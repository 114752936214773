body, HTML, #root, .App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

section {
  margin: 0;
}

h1, h2, p {
  font-family: 'Lobster Two', cursive;
  margin: 0;
  font-weight: normal;
  font-style: italic;
}

p {
  font-family: 'Open Sans', sans-serif;
}

#sky {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(188, 208, 221);
  z-index: 5;
}

#sea {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75%;
  z-index: 10;
  transition: 2s ease-in-out;
}

#sea.moved-down {
  bottom: -50%;
}

#contact {
  position: fixed;
  top: 5%;
  padding-top: 30px;
  right: 0;
  color: white;
  padding-right: 5%;
  text-align: right;
  transition: 3s ease-in;
  transition-delay: 1s;
  z-index: 100;
}

#extra-links img, #extra-links button {
  margin: 15px 5px;
  height: 30px;
  /* opacity: 0; */
  transition: opacity 3s;
  cursor: pointer;
}

#extra-links button  {
  display: block;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  font-size: 18px;
  color: white;
  background: transparent;
  border: 2px solid white;
  border-radius: 10px;
  outline: none;
}

#contact:hover #extra-links img, #contact:hover #extra-links button {
  opacity: 1;
}

#contact.moved-down:hover #extra-links button {
  opacity: 0;
}

.email p {
  font-family: 'Lobster Two', cursive;
  font-style: italic;
}

main {
  position: fixed;
  top: 0;
  left: 0;
  height: 75%;
  width: 75%;
  padding: 0;
  overflow: hidden;
  z-index: 15;
  opacity: 0;
  transition: 3s ease-in;
  transition-delay: 1s;
}

main.visible {
  opacity: 1;
}

main .projects-wrapper {
  overflow-x: scroll;
  height: 120%;
  width: 100%;
  padding: 0;
  margin: 0;
}

main ul {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 70%;
  width: 100%;
  white-space: nowrap;
  list-style-type: none;
  display: flex;
  margin: 0;
  background: white;
}

main ul::-webkit-scrollbar {
  display: none;
}

main ul li {
  overflow-y: hidden;
  overflow-x: scroll;
  display: block;
  width: 40%;
  height: 100%;
  margin: 20px 20px 0 0;
  box-sizing: border-box;
  background: white;
  list-style-type: none;
  flex-shrink: 0;
  padding: 20px;
  white-space: normal;
  overflow: scroll;
  border-right: 2px solid rgb(188, 208, 221);
}

main ul li:last-of-type {
  border-right: none;
}

main ul li p {
  margin-top: 10px;
  font-size: 14px;
}

.project-links {
  font-size: 12px;
}

.project-links a {
  cursor: pointer;
}

.project-links a:hover {
  text-decoration: underline;
}
main ul li h2 {
  font-size: 24px;
}

ul .list-spacer {
  display: block;
  width: 40%;
  height: 100%;
  flex-shrink: 0;
}

.projects-overlay {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 0;
  z-index: 10;
  margin-top: 0;
  background-image: linear-gradient(120deg, rgba(188, 208, 221, 0) 35%, rgba(188, 208, 221, 1) 75%);
  pointer-events: none;
}

.projects-overlay--bottom {
  background-image: linear-gradient(180deg, rgba(188, 208, 221, 0) 40%, rgba(188, 208, 221, 1) 100%);
}

.project-image {
  display: block;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 950px) {
  #contact.moved-down {
    opacity: 0;
  }

  main {
    width: 100%;
  }

  main .projects-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  main ul {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70%;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0;
  }
  
  main ul::-webkit-scrollbar {
    display: none;
  }
  
  main ul li {
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-right: none;
    border-bottom: 2px solid rgb(188, 208, 221);
    box-sizing: border-box;
  }

  .projects-overlay {
    background-image: none;
  }
  
  .projects-overlay--bottom {
    background-image: linear-gradient(180deg, rgba(188, 208, 221, 0) 40%, rgba(188, 208, 221, 1) 90%);
  }
}